import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*
//OLD
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);
*/

// Check if the script is running in a browser environment
// We only HYDRATE IT once the window is present, hence on client,
// And only if the are childnodes (which means it has been pre-rendered).
// If no children, then it is a regular react run, like `yarn start`, and we
// need to fully render the whole root and stuff.
if (typeof window !== 'undefined') {
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    // WE MIGHT BE USING A SSR PRE-STRING-RENDERED REACT. SO WE NEED TO HYDRATE the react code already present
    // console.log('#REACT_INDEX# HYDRATE')
    ReactDOM.hydrateRoot(rootElement,
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );
  } else {
    // WE MIGHT BE USING A SIMPLE OLD REACT BUILD, NO SSR, PROBABLY yarn start. So Render.
    // console.log('#REACT_INDEX# RENDER')
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );
  }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
