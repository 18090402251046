import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
//TIP TO REDUCE SIZE PERFORMANCE= https://github.com/mifi/react-lottie-player/issues/11

import Header from '../components/Header'
import AppStoreBadge from '../components/AppstoreBadge'

import useScreenSize from '../hooks/useScreenSize'
import useMobileScreen from '../hooks/useMobileScreen'

import { CDN_ENDPOINT } from '../logic/config'
import Footer from '../components/Footer'
import ButtonLight from '../components/ButtonLight'

/// NOTE ON SVG: React doesnt support SVGs with tags with ":". Replace with camelcase: https://stackoverflow.com/questions/58022436/how-to-include-svg-file-in-react
/*
import ImageLake from '../assets/images/Web_Lake.svg'
import ImageSky from '../assets/images/Web_Sky.svg'
import ImageMountains from '../assets/images/Web_Mountains.svg'
import ImageShadows from '../assets/images/Web_Shadows.svg'

import ImageTopBushLeaves from '../assets/images/home/Leaves_Top.svg'
import ImageContentTags from '../assets/images/home/Tags.png'
import ImageContentCuratedMixLeft from '../assets/images/home/Curated_Left.png'
import ImageContentCuratedMixRight from '../assets/images/home/Curated_Right.png'
import ImageContentIcons from '../assets/images/home/Icons.svg'
import ImageContentMixer from '../assets/images/home/Mixer.png'
*/

//import LottieBushes from '../assets/lotties/home/bushes.json'
//import LottieStars from '../assets/lotties/home/stars.json'
//import LottieLights from '../assets/lotties/home/lights.json'
//import LottieWaterWaves from '../assets/lotties/home/water_waves.json'

//import Slider from 'rc-slider';
//import 'rc-slider/assets/index.css';

//import IconAudioWaves from '../assets/audio/NATURE_WAVES_LAPPING-001/icon_nature_waves_lapping-001.png'
//import IconAudioFire from '../assets/audio/NOISE_FIRE_CRACKLE-001/icon_noise_fire_crackle-001.png'
//import IconAudioRain from '../assets/audio/NATURE_RAIN_TARP-001/icon_nature_rain_tarp-001.png'
//import IconAudioWind from '../assets/audio/NATURE_WIND-001/icon_nature_wind-001.png'


//import AudioWaves from '../assets/audio/NATURE_WAVES_LAPPING-001/nature_waves_lapping-001.wav'
//import AudioFire from '../assets/audio/NOISE_FIRE_CRACKLE-001/noise_fire_crackle-001.wav'
//import AudioRain from '../assets/audio/NATURE_RAIN_TARP-001/nature_rain_tarp-001.wav'
//import AudioWind from '../assets/audio/NATURE_WIND-001/nature_wind-001.wav'



const AUDIO_TRACK = {
  first: "first",
  second: "second",
  third: "third",
  fourth: 'fourth'
}

function Home() {

    ///
    /// STATES
    ///
    const [words, setWords] = useState(["sleep", "focus", "relax", "work"]);
    const [wordIndex, setWordIndex] = useState(0)

    const [showTitle, setShowTitle] = useState(false)
    const [showSubTitle, setShowSubTitle] = useState(false)
    const [showSubscribe, setShowSubscribe] = useState(false)

    const [globalPlaying, setGlobalPlaying] = useState(false)
    const [isPlaying, setIsPlaying] = useState([]) //array the AUDIO_TRACKs playing
    const [audioVolumeWaves, setAudioVolumeWaves] = useState(0.5)
    const [audioVolumeWind, setAudioVolumeWind] = useState(0.5)

    const [showMailForm, setShowMailForm] = useState(false)

    /// LOTTIES
    const [dataLottieBushes, setDataLottieBushes] = useState();
    const [dataLottieStars, setDataLottieStars] = useState();
    const [dataLottieLights, setDataLottieLights] = useState();
    const [dataLottieWaterWaves, setDataLottieWaterWaves] = useState();

    /// AUDIOS
    const [dataAudioWaves, setDataAudioWaves] = useState();
    const [dataAudioFire, setDataAudioFire] = useState();
    const [dataAudioRain, setDataAudioRain] = useState();
    const [dataAudioWind, setDataAudioWind] = useState();

    /// ICONS AUDIO
    const [IconAudioWaves, setIconAudioWaves] = useState("");
    const [IconAudioFire, setIconAudioFire] = useState("");
    const [IconAudioRain, setIconAudioRain] = useState("");
    const [IconAudioWind, setIconAudioWind] = useState("");

    /// IMAGES
    const [ImageLake, setImageLake] = useState("")
    const [ImageSky, setImageSky] = useState("")
    const [ImageMountains, setImageMountains] = useState("")
    const [ImageShadows, setImageShadows] = useState("")

    const [ImageTopBushLeaves, setImageTopBushLeaves] = useState("")
    const [ImageContentTags, setImageContentTags] = useState("")
    const [ImageContentCuratedMixLeft, setImageContentCuratedMixLeft] = useState("")
    const [ImageContentCuratedMixRight, setImageContentCuratedMixRight] = useState("")
    const [ImageContentIcons, setImageContentIcons] = useState("")
    const [ImageContentMixer, setImageContentMixer] = useState("")

   
    ///
    /// REFS
    ///
    const dynamicWordRef = useRef()

    const audioRefFirst = React.createRef();
    const audioRefSecond = React.createRef();
    const audioRefThird = React.createRef();
    const audioRefFourth = React.createRef()

    //const soundAudioFirst = new Audio(getAudioSrcForTrack(AUDIO_TRACK.first));
    //const soundAudioSecond = new Audio(getAudioSrcForTrack(AUDIO_TRACK.second));
    //const soundAudioThird = new Audio(getAudioSrcForTrack(AUDIO_TRACK.third));
    //const soundAudioFourth= new Audio(getAudioSrcForTrack(AUDIO_TRACK.fourth));

    ///
    /// Hooks
    ///

    const screenSize = useScreenSize()
    const mobileScreen = useMobileScreen()


    ///
    /// Use Effect
    ///
    useEffect(()=>{
      /// Show title after 2 secs
      setTimeout(()=>{
        setShowTitle(true);
      }, 0/*250*/)

      /// Show subtitle after 4 secs
      setTimeout(()=>{
        setShowSubTitle(true);
      }, 0/*500*/)

      /// Show subtitle after 4 secs
      setTimeout(()=>{
        setShowSubscribe(true)
      }, 0/*500*/)

      /// LOTTIE LOAD to save stuff
      /// https://github.com/mifi/react-lottie-player/issues/11
      import('../assets/lotties/home/bushes.json').then(setDataLottieBushes)
      import('../assets/lotties/home/stars.json').then(setDataLottieStars)
      import('../assets/lotties/home/lights.json').then(setDataLottieLights)
      import('../assets/lotties/home/water_waves.json').then(setDataLottieWaterWaves)

      /// SAME FOR AUDIO LAZY LOAD
      const DEVELOPMENT = false //process.env.NODE_ENV === 'development'
      if(DEVELOPMENT){
        import('../assets/audio/NATURE_WAVES_LAPPING-001/nature_waves_lapping-001.wav').then(setDataAudioWaves)
        import('../assets/audio/NOISE_FIRE_CRACKLE-001/noise_fire_crackle-001.wav').then(setDataAudioFire)
        import('../assets/audio/NATURE_RAIN_TARP-001/nature_rain_tarp-001.wav').then(setDataAudioRain)
        import('../assets/audio/NATURE_WIND-001/nature_wind-001.wav').then(setDataAudioWind)
      }else{
        setDataAudioWaves(`${CDN_ENDPOINT}/web/assets/audio/NATURE_WAVES_LAPPING-001/nature_waves_lapping-001.wav`)
        setDataAudioFire(`${CDN_ENDPOINT}/web/assets/audio/NOISE_FIRE_CRACKLE-001/noise_fire_crackle-001.wav`)
        setDataAudioRain(`${CDN_ENDPOINT}/web/assets/audio/NATURE_RAIN_TARP-001/nature_rain_tarp-001.wav`)
        setDataAudioWind(`${CDN_ENDPOINT}/web/assets/audio/NATURE_WIND-001/nature_wind-001.wav`)
      }

      /// SAME FOR ICONS OF AUDIO
      if(DEVELOPMENT){
        //
        import('../assets/audio/NATURE_WAVES_LAPPING-001/icon_nature_waves_lapping-001.png').then(setIconAudioWaves)
        import('../assets/audio/NOISE_FIRE_CRACKLE-001/icon_noise_fire_crackle-001.png').then(setIconAudioFire)
        import('../assets/audio/NATURE_RAIN_TARP-001/icon_nature_rain_tarp-001.png').then(setIconAudioRain)
        import('../assets/audio/NATURE_WIND-001/icon_nature_wind-001.png').then(setIconAudioWind)
      }else{
        setIconAudioWaves(`${CDN_ENDPOINT}/web/assets/audio/NATURE_WAVES_LAPPING-001/icon_nature_waves_lapping-001.png`)
        setIconAudioFire(`${CDN_ENDPOINT}/web/assets/audio/NOISE_FIRE_CRACKLE-001/icon_noise_fire_crackle-001.png`)
        setIconAudioRain(`${CDN_ENDPOINT}/web/assets/audio/NATURE_RAIN_TARP-001/icon_nature_rain_tarp-001.png`)
        setIconAudioWind(`${CDN_ENDPOINT}/web/assets/audio/NATURE_WIND-001/icon_nature_wind-001.png`)
      }

      /// FOR IMAGES
      if(!DEVELOPMENT){
        setImageLake(`${CDN_ENDPOINT}/web/assets/images/Web_Lake.svg`)
        setImageSky(`${CDN_ENDPOINT}/web/assets/images/Web_Sky.svg`)
        setImageMountains(`${CDN_ENDPOINT}/web/assets/images/Web_Mountains.svg`)
        setImageShadows(`${CDN_ENDPOINT}/web/assets/images/Web_Shadows.svg`)

        setImageTopBushLeaves(`${CDN_ENDPOINT}/web/assets/images/home/Leaves_Top.svg`)
        setImageContentTags(`${CDN_ENDPOINT}/web/assets/images/home/Tags.png`)
        setImageContentCuratedMixLeft(`${CDN_ENDPOINT}/web/assets/images/home/Curated_Left.png`)
        setImageContentCuratedMixRight(`${CDN_ENDPOINT}/web/assets/images/home/Curated_Right.png`)
        setImageContentIcons(`${CDN_ENDPOINT}/web/assets/images/home/Icons.svg`)
        setImageContentMixer(`${CDN_ENDPOINT}/web/assets/images/home/Mixer.png`)
      }

      /// Media for player
      navigator.mediaSession.metadata = new MediaMetadata({
        title: "nuits",
        artist: "getnuits.com",
        artwork: [
          {
            src: "https://dummyimage.com/96x96",
            sizes: "96x96",
            type: "image/png",
          },
          {
            src: "https://dummyimage.com/128x128",
            sizes: "128x128",
            type: "image/png",
          },
          {
            src: "https://dummyimage.com/192x192",
            sizes: "192x192",
            type: "image/png",
          },
          {
            src: "https://dummyimage.com/256x256",
            sizes: "256x256",
            type: "image/png",
          },
          {
            src: "https://dummyimage.com/384x384",
            sizes: "384x384",
            type: "image/png",
          },
          {
            src: "https://dummyimage.com/512x512",
            sizes: "512x512",
            type: "image/png",
          }
        ]});
        /*
        navigator.mediaSession.setActionHandler("play", () => {
          pauseCurrentlyPlaying();
        });
        navigator.mediaSession.setActionHandler("pause", () => {
          pauseCurrentlyPlaying();
                // set playback state
          navigator.mediaSession.playbackState = "paused";
        });
        navigator.mediaSession.setActionHandler("stop", () => {
          pauseCurrentlyPlaying();
        });
        */

        if(mobileScreen){

          /// HELPERS
          /// https://stackoverflow.com/questions/66123016/interpolate-between-two-colours-based-on-a-percentage-value
          function getRgb(color) {
            let [r, g, b] = color.replace('rgb(', '')
              .replace(')', '')
              .split(',')
              .map(str => Number(str));;
            return {
              r,
              g,
              b
            }
          }
          function colorInterpolate(colorA, colorB, intval) {
            const rgbA = getRgb(colorA),
              rgbB = getRgb(colorB);
            const colorVal = (prop) =>
              Math.round(rgbA[prop] * (1 - intval) + rgbB[prop] * intval);
            return {
              r: colorVal('r'),
              g: colorVal('g'),
              b: colorVal('b'),
            }
          }

          const functionChangeColorScroll = () => {
            const metaTag = document.querySelector('meta[name="theme-color"]');
            var percentageScroll = window.scrollY / (window.innerHeight * 0.7);
            if(percentageScroll > 1)
            {
              percentageScroll = 1;
            }else if(percentageScroll < 0)
            {
              percentageScroll = 0
            }
            const rgbNew = colorInterpolate(
              'rgb(28,85,167)',
              'rgb(156,119,193)', //'rgb(41,40,80)', 
              percentageScroll
            );
            const rgbNewString = `rgb( ${rgbNew.r}, ${rgbNew.g}, ${rgbNew.b})`;
            console.log('color',rgbNewString)
            //if (window.scrollY / window.innerHeight > 0.8) {
            //  metaTag.setAttribute("content", "#000000");
            //} else {
            //  metaTag.setAttribute("content", "#ffffff");
            //}
            metaTag.setAttribute("content", rgbNewString);
          }
          window.addEventListener('scroll', functionChangeColorScroll, false);
          return () => window.removeEventListener("scroll", functionChangeColorScroll);
          
        }

        
    },[])

    /// Audio observed onblur window:
    /// As noted here (https://stackoverflow.com/questions/56541342/react-hooks-why-is-current-null-for-useref-hook),
    /// We need to set it after audioRef is set, or the function will be null
    //TODO: FIND A PROPER WAY OF UNSUBSCRIBE of thIS, TO CLEAN onBLUR if changes page
    // As of now, I just check audio undefined on pauseCurrentlyPlaying(), but should be better
    useEffect(()=>{
      window.onblur = () => {
        pauseCurrentlyPlaying();
      }
    }, [audioRefFirst])

    /// Effect for word animation
    useEffect(()=>{
      const timeout = setTimeout(() => {
        var newIndex = wordIndex + 1;
        if(newIndex > words.length - 1)
        {
          newIndex = 0;
        }
        setWordIndex(newIndex);

        /// Ref to dynamically change width of word - ** SEE NOTE BELOW **
        /// console.log('REF SIZE', dynamicWordRef.current.scrollWidth)
        //dynamicWordRef.current.style.width = dynamicWordRef.current.scrollWidth;
        dynamicWordRef.current.style.width = `${dynamicWordRef.current.scrollWidth}px`; // **NOTE**: apparently this doesnt resize back when word is smaller, but we do not care, its better. thi thing is that it resizes when word is bigger, and if it stays like that, no biggie
        //setWordRequiredWidth(dynamicWordRef.current.scrollWidth)

      }, 2000);
  
      return () => {
        // clears timeout before running the new effect
        clearTimeout(timeout);
      };
    },[wordIndex])

    ///
    /// METHODS
    ///

    // Tapped on icon
    async function playBackgroundAudio(audioTrack = AUDIO_TRACK.first) {

      /*
      const soundAudioFirst = new Audio(getAudioSrcForTrack(AUDIO_TRACK.first));
      const soundAudioSecond = new Audio(getAudioSrcForTrack(AUDIO_TRACK.second));
      console.log('SOUND',soundAudioFirst)
      soundAudioFirst.play()
      soundAudioSecond.play()
      //sound.play();
      //sound.currentTime = 0;
      return;
      */
      
      /*
      const sound = new Audio('path/to/your/sound/notification.mp3');
  
      sound.play();
      sound.pause();
      sound.currentTime = 0;
  
      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
      */

      var wasPlaying = [].concat(isPlaying);

      if(wasPlaying.includes(audioTrack))
      {
        const indexToRemove = wasPlaying.indexOf(audioTrack)
        if(indexToRemove > -1)
        {
          wasPlaying.splice(indexToRemove, 1)
        }
      
        setIsPlaying(wasPlaying)
        getAudioRefForTrack(audioTrack).current.pause();

      }else{
        wasPlaying.push(audioTrack)
        setIsPlaying(wasPlaying)

        const refAudio = getAudioRefForTrack(audioTrack)
        refAudio.current.volume = getAudioVolumeForTrack(audioTrack)
        refAudio.current.play()
      }

      /// If any left, is playing
      if(wasPlaying.length > 0)
      {
        setGlobalPlaying(true)
      }else{
        setGlobalPlaying(false)
      }

    }
    const pauseCurrentlyPlaying = () => {
      for (const [key, value] of Object.entries(AUDIO_TRACK)) {
        //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
        const refAudio = getAudioRefForTrack(value)
        //console.log('REF AUDIO IS ',refAudio)
        if(refAudio?.current ?? undefined === undefined){
          console.log('pauseCurrentlyPlaying - REF AUDIO IS UNDEFINED, CLEAN onBlur AND EXIT')
          window.onblur = () => {
            //
          }
          return
        }
        refAudio.current.pause();
      }
      setIsPlaying([])
    }

    // Get audioref for track
    const getAudioSrcForTrack = (audioTrack) => {
      const DEVELOPMENT = false; //process.env.NODE_ENV === 'development' ? true : false
      switch(audioTrack)
      {
        case AUDIO_TRACK.first:
          return dataAudioWaves
          //return DEVELOPMENT ? AudioWaves : `${CDN_ENDPOINT}/web/audio/NATURE_WAVES_LAPPING-001/nature_waves_lapping-001.wav`;
        case AUDIO_TRACK.second:
          return dataAudioFire
          //return DEVELOPMENT ? AudioFire : `${CDN_ENDPOINT}/web/audio/NOISE_FIRE_CRACKLE-001/noise_fire_crackle-001.wav`;
        case AUDIO_TRACK.third:
          return dataAudioRain
          //return DEVELOPMENT ? AudioRain : `${CDN_ENDPOINT}/web/audio/NATURE_RAIN_TARP-001/nature_rain_tarp-001.wav`;
        case AUDIO_TRACK.fourth:
          return dataAudioWind
          //return DEVELOPMENT ? AudioWind : `${CDN_ENDPOINT}/web/audio/NATURE_WIND-001/nature_wind-001.wav`;
      }
    }
    const getAudioRefForTrack = (audioTrack) => {
      switch(audioTrack)
      {
        case AUDIO_TRACK.first:
          return audioRefFirst
        case AUDIO_TRACK.second:
          return audioRefSecond
        case AUDIO_TRACK.third:
          return audioRefThird;
        case AUDIO_TRACK.fourth:
          return audioRefFourth
      }
    }
    const getAudioVolumeForTrack = (audioTrack) => {
      switch(audioTrack)
      {
        case AUDIO_TRACK.first:
          return 0.4
        case AUDIO_TRACK.second:
          return 0.4
        case AUDIO_TRACK.third:
          return 0.4
        case AUDIO_TRACK.fourth:
          return 0.4
      }
    }

    // Volume changes
    const changedTrackVolumeWaves = (newVal) => {
      //setAudioVolumeWaves(newVal)
      //audioRefWaves.current.volume = audioVolumeWaves;
    }
    const changedTrackVolumeWind = (newVal) => {
     // setAudioVolumeWind(newVal)
      //audioRefWind.current.volume = audioVolumeWind;
    }

    ///
    /// RENDER
    ///
    return (
      <React.Fragment>
          
          
          <audio preload="none" src={getAudioSrcForTrack(AUDIO_TRACK.first)} ref={getAudioRefForTrack(AUDIO_TRACK.first)} loop />
          <audio preload="none" src={getAudioSrcForTrack(AUDIO_TRACK.second)} ref={getAudioRefForTrack(AUDIO_TRACK.second)} loop />
          <audio preload="none" src={getAudioSrcForTrack(AUDIO_TRACK.third)} ref={getAudioRefForTrack(AUDIO_TRACK.third)} loop />
          <audio preload="none" src={getAudioSrcForTrack(AUDIO_TRACK.fourth)} ref={getAudioRefForTrack(AUDIO_TRACK.fourth)} loop />
  

          <div style={style.whole}>

            {/* HEADER */}
            <Header/>

            {/* TOP ANIMATION PACK */}
            <div style={style.firstFrontPack(mobileScreen, screenSize)}>

              {/* BACK -> SKY - instead of an image, an area always covered with the image as background, so it resizes */}
              <div style={style.firstFrontPackSky(mobileScreen, screenSize, ImageSky)}/>

              {/* BACK -> STARS */}
              {
                dataLottieStars!==undefined
                ? <Lottie animationData={dataLottieStars} style={style.firstFrontPackStars(mobileScreen, screenSize)} />
                : <React.Fragment/>
              }
              {/*<Lottie animationData={LottieStars} style={style.firstFrontPackStars(mobileScreen, screenSize)} />*/}

              {/* BACK -> LAKE */}
              <img src={ImageLake} style={style.firstFrontPackLake(mobileScreen, screenSize)} alt='Lake'/>

              {/* BACK -> MOUNTAINS */}
              <img src={ImageMountains} style={style.firstFrontPackMountains(mobileScreen, screenSize)} alt='Mountains'/>

              {/* BACK -> WATER WAVES */}
              {
                dataLottieWaterWaves!==undefined
                ? <Lottie animationData={dataLottieWaterWaves} style={style.firstFrontPackWaterWaves(mobileScreen, screenSize)} />
                : <React.Fragment/>
              }
              {/*<Lottie animationData={LottieWaterWaves} style={style.firstFrontPackWaterWaves(mobileScreen, screenSize)} />*/}

              {/* BACK -> SHADOWS */}
              <img src={ImageShadows} style={style.firstFrontPackShadows(mobileScreen, screenSize)} alt='Shadows over the lake'/>

              {/* BACK -> LIGHTS */}
              {
                dataLottieLights!==undefined
                ? <Lottie animationData={dataLottieLights} style={style.firstFrontPackLights(mobileScreen, screenSize)} />
                : <React.Fragment/>
              }
              {/*<Lottie animationData={LottieLights} style={style.firstFrontPackLights(mobileScreen, screenSize)} />*/}

              {/* BACK -> BUSHES */}
              {
                dataLottieBushes!==undefined
                ? <Lottie animationData={dataLottieBushes} style={style.firstFrontPackBushes(mobileScreen, screenSize)} />
                : <React.Fragment/>
              }
              {/*<Lottie animationData={LottieBushes} style={style.firstFrontPackBushes(mobileScreen, screenSize)} />*/}

              {/* FLOATING COPY */}
              <div style={{...style.firstFrontPackFloatingCopy(mobileScreen, screenSize), ...(showTitle ? style.fadeIn() : {})}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <div ref={dynamicWordRef} style={{/*width: mobileScreen ? 90 : 130, */display: 'inline-block', transition: 'width 0.2s', marginRight: mobileScreen ? 8 : 10, fontWeight: '500', textAlign: 'right'}}>
                    {words[wordIndex]}
                  </div>
                  <div style={{marginLeft: 2}}>
                    with nuits.
                  </div>
                </div>
                <h1 style={{...{opacity: 0, fontSize: mobileScreen ? 18 : 24, fontWeight: '300', display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0}, ...(showSubTitle ? style.fadeIn() : {})}}>
                beautifully animated soundscapes on your phone{mobileScreen ? " " : <br/>}that help you sleep, focus and relax.
                </h1>
                {
                  !showMailForm
                  ?  <div style={{...{opacity: 0, marginTop: 22, display: 'flex', flexDirection: 'row', justifyContent: 'center'}, ...(showSubscribe ? style.fadeIn() : {})}}>
                      <AppStoreBadge/>
                    </div>
                  :  <div style={{...{opacity: 0, marginTop: 12, fontSize: mobileScreen ? 14 : 20, fontWeight: '500', display: 'flex', flexDirection: 'row', justifyContent: 'center'}, ...(showSubscribe ? style.fadeIn() : {})}}>
                      <div id="mc_embed_signup" style={{borderRadius: 6, overflow: 'hidden'}}>
                        <form action="https://studio.us21.list-manage.com/subscribe/post?u=fa367080052acbd8443dc1887&amp;id=c8fe9edf62&amp;f_id=0016e7e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate
                        >
                          <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="email address" required className='inputDefault'
                            style={{height: 20, borderRadius: 0, color: 'white', backgroundColor: 'rgb(156,119,193)', borderWidth:'0px', padding: 6, paddingLeft: 10, boxShadow: 'none'}}/>
                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_fa367080052acbd8443dc1887_c8fe9edf62" tabIndex="-1" defaultValue=""/></div>
                            <input type="submit" value="notify me" name="subscribe" id="mc-embedded-subscribe"
                            style={{height: 32, borderRadius: 0, border: 0, background: 'rgb(28,85,167)', color: 'white', cursor: 'pointer', fontWeight: '500'}}/>
                        </form>
                        </div>
                    </div>
                }
               
              </div>


              
            </div>

            {/* MIDDLE BUSH PART */}
            <div style={style.middleBushPart(mobileScreen, screenSize)}>

              {/* PLAYER */}
              <div style={style.playerArea(mobileScreen, screenSize)}>
                Tap the icons to play a quick mix

                <div style={style.playerAreaIcons(mobileScreen, screenSize)}>
                  <div onClick={()=>{playBackgroundAudio(AUDIO_TRACK.first)}} style={{cursor: 'pointer', width: 30, height: 30, backgroundImage: `url(${IconAudioWaves})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity: isPlaying.includes(AUDIO_TRACK.first) ? 1 : 0.3, transition: `opacity ${500}ms ease-in-out`}}>
                        
                  </div>

                  <div style={{flex: 1}}/>

                  <div onClick={()=>{playBackgroundAudio(AUDIO_TRACK.second)}} style={{cursor: 'pointer', width: 30, height: 30, backgroundImage: `url(${IconAudioFire})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity: isPlaying.includes(AUDIO_TRACK.second) ? 1 : 0.3, transition: `opacity ${500}ms ease-in-out`}}>
                        
                  </div>

                  <div style={{flex: 1}}/>

                  <div onClick={()=>{playBackgroundAudio(AUDIO_TRACK.third)}} style={{cursor: 'pointer', width: 30, height: 30, backgroundImage: `url(${IconAudioRain})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity: isPlaying.includes(AUDIO_TRACK.third) ? 1 : 0.3, transition: `opacity ${500}ms ease-in-out`}}>
                        
                  </div>

                  <div style={{flex: 1}}/>

                  <div onClick={()=>{playBackgroundAudio(AUDIO_TRACK.fourth)}} style={{cursor: 'pointer', width: 30, height: 30, backgroundImage: `url(${IconAudioWind})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity: isPlaying.includes(AUDIO_TRACK.fourth) ? 1 : 0.3, transition: `opacity ${500}ms ease-in-out`}}>
                        
                  </div>
                  
                </div>
              </div>

            </div>

            {/* CONtENT PART*/}
            <div style={style.detailContentPart(mobileScreen, screenSize)}>
                {/* tOP LEAVES */}
                <img src={ImageTopBushLeaves} style={{width: '100%', position: 'absolute', top: 0}} alt='Leaves under the main bush'/>
                
                {/* SPACER TO PREVENT CONTENT BELOW LEAVES */}
                <div style={{height: 100}}/>

                 {/* Cloud TAG */}
                <div style={{...style.contentHorizontalRowContainer(mobileScreen, screenSize), height: 400}}>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    <h2 style={{...style.contentHorizontalRowContainerItemTitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                      The right sounds for your goals
                    </h2>
                    <h3 style={{...style.contentHorizontalRowContainerItemSubtitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                      Focus, relax, calm your baby...
                    </h3>
                    <img src={ImageContentTags} style={{width: '400px', height: 'auto', marginTop: '10px', marginBottom: '10px'}} alt='Cloud of tags: sleep, calm baby, calm pet, fight anxiety, reduce stress, focus, sleep.'/>
                  </div>
                </div>

                {/* Curated Mixes */}
                <div style={style.contentHorizontalRowContainer(mobileScreen, screenSize)}>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    <img src={ImageContentCuratedMixLeft} style={{width: '100%', maxWidth: 500, height: 'auto'}} alt='Curated soundscape previews to soothe baby, calm pet, and more'/>
                  </div>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    {/* item inside item, so we can clamp the max width the text container can reach */}
                    <div style={{...style.contentHorizontalRowContainerItem(mobileScreen, screenSize), alignItems: 'flex-start', maxWidth: '400px', marginLeft: '20px', marginRight: '20px'}}>
                      <h2 style={{...style.contentHorizontalRowContainerItemTitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                        Curated mixes
                      </h2>
                      <h3 style={{...style.contentHorizontalRowContainerItemSubtitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                        Soundscapes For You
                      </h3>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Explore our specially curated playlists, each combined with engaging visual scenes for various purposes, providing a blend of audio and visual experiences.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Whether it's for a peaceful night's sleep, dealing with noisy surroundings, calming a restless baby, or easing anxiety, we have the perfect soundscape for you. Just choose, play, and enjoy the experience.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Our soundscapes also cater to those who prefer a gentle background noise for focus or relaxation, need help calming pets, or seek relief from tinnitus. Find a scene that fits your unique need.
                      </p>
                      <Link reloadDocument to={'/soundscapes'} style={{width: '100%', textDecoration: 'none', marginTop: '20px'}}>
                        <ButtonLight title="Explore Curated Mixes"/>
                      </Link>
                    </div>
                  </div>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    <img src={ImageContentCuratedMixRight} style={{width: '100%', maxWidth: 500, height: 'auto'}} alt='Curated soundscape previews for sleep relax and focus'/>
                  </div>
                </div>

                {/* ALl the soothing sounds available */}
                <div style={style.contentHorizontalRowContainer(mobileScreen, screenSize)}>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    {/* item inside item, so we can clamp the max width the text container can reach */}
                    <div style={{...style.contentHorizontalRowContainerItem(mobileScreen, screenSize), alignItems: 'flex-start', maxWidth: '400px',  marginLeft: '20px', marginRight: '20px'}}>
                      <h2 style={{...style.contentHorizontalRowContainerItemTitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                        Soothing sounds
                      </h2>
                      <h3 style={{...style.contentHorizontalRowContainerItemSubtitle(mobileScreen, screenSize),margin: 0}}> {/* H tags have margin */}
                        Nature, Water, Fan, White Noise...
                      </h3>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Find better sleep with sounds like rain, waterfalls, and wind, or the calming chirps of birds and crickets. Even the croaking of frogs can be relaxing.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Prefer water sounds? We have ocean waves, flowing rivers, and babbling creeks, all great for sleeping, relaxing, or focusing.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Our app also includes mechanical sounds like airplane cabins, fans, and more. These are perfect for blocking out noise and helping you or your baby sleep, or for when you need to concentrate. This includes white noise, pink noise, green noise, and other variants.
                      </p>
                    </div>
                  </div>
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    <img src={ImageContentIcons} style={{width: '100%', maxWidth: 500, height: 'auto'}} alt='Icons depicting available sounds within Nuits mobile app, including fans, white noise, creek, nature, and more.'/>
                  </div>
                </div>

                {/* Create your perfect mix */}
                <div style={style.contentHorizontalRowContainer(mobileScreen, screenSize)}>

                                 
                  {/* Empty mini spacer */}
                  <div style={{width: 200}}/>

                  {/* Img */}
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    <img src={ImageContentMixer} style={{width: '100%', minWidth: 200, maxWidth: 300, height: 'auto'}} alt='Screenshot of the iphone app, showing the mixer, where you can pick the sounds you want'/>
                  </div>

                  {/* Empty mini spacer */}
                  <div style={{width: 50}}/>
                  {/* Text */}
                  <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}>
                    {/* item inside item, so we can clamp the max width the text container can reach */}
                    <div style={{...style.contentHorizontalRowContainerItem(mobileScreen, screenSize), alignItems: 'flex-start', minWidth: '380px', maxWidth: '400px',  marginLeft: '20px', marginRight: '20px'}}>
                      <h2 style={{...style.contentHorizontalRowContainerItemTitle(mobileScreen, screenSize),margin: 0}}>
                      Create Your Ideal Sound Mix
                      </h2>
                      <h3 style={{...style.contentHorizontalRowContainerItemSubtitle(mobileScreen, screenSize),margin: 0}}>
                      Choose Sounds To Sleep, Focus or Relax
                      </h3>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Whatever your needs, Nuits has the solution. Create your own sound mixes for better sleep, enhanced focus, soothing a baby, or just to relax. It's all about what works for you.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Our user-friendly app makes mixing easy. Select from our top-quality sound library and adjust the levels to craft your perfect audio environment for sleep, focus, or relaxation.
                      </p>
                      <p style={style.contentHorizontalRowContainerItemText(mobileScreen, screenSize)}>
                      Explore over 95 sounds, neatly categorized and easy to find with a quick search. We're constantly adding new sounds to keep the library fresh and diverse.
                      </p>
                    </div>
                  </div>

                   {/* Empty space pusher*/}
                   <div style={style.contentHorizontalRowContainerItem(mobileScreen, screenSize)}/>
                </div>

                {/* Empty mini spacer */}
                <div style={{height: 100}}/>

                {/* FOOTER - Image plus container */}
                <Footer/>
            </div>
          </div>
      </React.Fragment>
    );
  }
  
  export default Home;
  
  const style = {

    //
    //CONTAINER
    //

    //The whole div parent
    whole:{	
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    //
    // First front top part
    //

    //Pack main
    firstFrontPack : (isMobile, screenSize) => {
      const screenRatio = screenSize.width / screenSize.height;
      const idealAspectRatio = 2; //hardcoded size of the animation rectangle (2048x1024)
      const getProperHeight = () => {
        /// On mobile I dont want jumps
        if(isMobile)
        {
          return '90vh'
          //return '100vh'
        }
        /// On laptop, variable
        if(screenRatio > idealAspectRatio){
          return screenSize.width / idealAspectRatio;
        }else{
           // NEW: IF NO MOBILE, SHORTER! TO FIT REVIEWS
          return '80vh'
          //OLD: full height
          return  screenSize.height;
        }
      }
      return {
          width: '100%',
          overflow: 'hidden', //this is key, on mobile, we resize everything and move it up, but this allow us to crop excess
          height: getProperHeight(), //'80vh',
          transition: isMobile ? undefined : 'height 1.0s', //important on iphone that hides/shows the address bar, which changes height, this way does not jump
          minHeight: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          boxSizing: 'border-box',
          position: 'relative' //this is key so the items inside can align to relative bottom, and not parent whole bottom
      }
    },

    //Sky
    firstFrontPackSky: (isMobile, screenSize, ImageSky) => {
      return{
          //Being like this, we cover the whole area
          width: '100%',
          height: '100%',
          minHeight: '500px',
          backgroundImage: `url(${ImageSky})`,
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
      }
    },
    firstFrontPackStars: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        bottom: isMobile ? undefined : undefined,
        top: isMobile ? 0 : 0,
      }
    },
    firstFrontPackLake: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        bottom: 0
      }
    },
    firstFrontPackMountains: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        transform: isMobile ? `translate(${screenSize.width/2}px, 0)` : undefined,  //on mobile, translate
        bottom: 0,
      }
    },
    firstFrontPackShadows: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        transform: isMobile ? `translate(${screenSize.width/2}px, 0)` : undefined,  //on mobile, translate
        bottom: 0
      }
    },
    firstFrontPackWaterWaves: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        transform: isMobile ? `translate(${screenSize.width/2}px, 0)` : undefined,  //on mobile, translate
        //backgroundColor: 'red',
        opacity: 1,
        bottom: 0
      }
    },
    firstFrontPackLights: (isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 3) : '100%',
        transform: isMobile ? `translate(${screenSize.width/2}px, 0)` : undefined,  //on mobile, translate
        opacity: 1,
        bottom: -4, //por algun motivo no me encajan bien. algo estoy haciendo mal
      }
    },
    firstFrontPackBushes:(isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? (screenSize.width * 2) : '100%',
        transform: isMobile ? `translate(${screenSize.width/2}px, 0)` : undefined,  //on mobile, translate
        //backgroundColor: 'red',
        opacity: 1,
        bottom: -10 //TODO: TELL JACO TO FIT THIS ANIMATION,
      }
    },
    firstFrontPackFloatingCopy:(isMobile, screenSize) => {
      return{
        position: 'absolute',
        width: isMobile ? '90%' : '60%',
        color: 'white',
        fontSize: isMobile ? 40 : 50,
        fontFamily: 'Signifika', //Inter
        fontWeight: '300',
        textAlign: 'center',
        transform: isMobile ? `translate(0, -${10}vh)` : undefined,  //on mobile, translate but relative to VH, not height which changes with address bar on safari //OLD: `translate(0, -${screenSize.height/10}px)`
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: `opacity ${500}ms ease-in-out`,
        opacity: 0,
      }
    },
    //
    //MIDDLE BUSH
    //
    middleBushPart:(isMobile, screenSize) => {
      return {
        backgroundColor: 'rgb(41, 40, 80)',
        height: screenSize.height / 2,
        minHeight: 300,
        maxHeight: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },

    /// Player Area
    playerArea: (isMobile, screenSize) => {
      return {
        width: isMobile ? '70%' : '90%',
        //background: 'rgba(255,255,255,0.05)',
        borderRadius: 18,
        paddingTop: 24,
        paddingRight: 60, 
        paddingLeft: 60,
        paddingBottom: 36,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        fontSize: isMobile ? 13 : 13,
        fontFamily: 'Signifika', //Inter
        fontWeight: '400',
        textAlign: 'center',
      }
    },
    playerAreaIcons: (isMobile, screenSize) => {
      return {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }
    },

    //
    //DETAILCONTENT
    //
    detailContentPart:(isMobile, screenSize) => {
      return {
        width: '100%',
        backgroundColor: 'rgb(2, 88, 149)',
        minHeight: screenSize.height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative' //this is key so the items inside can align to relative bottom, and not parent whole bottom
      }
    },
    contentHorizontalRowContainer: (isMobile, screenSize) => {
      return {
        display: 'flex',
        
        width: '100%',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 300,
        alignItems: 'stretch', //this forces the child to strech vertically
      }
    },
    contentHorizontalRowContainerItem: (isMobile, screenSize) => {
      return {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: 'Signifika',
        color: 'white'
      }
    },
    contentHorizontalRowContainerItemHiddenOnMobile: (isMobile, screenSize) => {
      return {
        display: isMobile ? 'none' : 'block'
      }
    },
    contentHorizontalRowContainerItemTitle: (isMobile, screenSize) => {
      return {
        color: 'rgb(255, 209, 101)',
        fontSize: 28,
        fontFamily: 'Signifika', //Inter
        fontWeight: '400',
      }
    },
    contentHorizontalRowContainerItemSubtitle: (isMobile, screenSize) => {
      return {
        color: 'white',
        fontSize: 22,
        fontFamily: 'Signifika', //Inter
        fontWeight: '300',
      }
    },
    contentHorizontalRowContainerItemText: (isMobile, screenSize) => {
      return {
        color: 'white',
        fontSize: 18,
        fontFamily: 'Signifika', //Inter
        fontWeight: '300',
        textAlign: 'left',
        marginTop: 15,
        marginBottom: 0 //to avoid <p> to keep it
      }
    },

    ///
    /// FADES

    fadeIn: () => {
      return {
        opacity:1,
        transition: `opacity ${250}ms ease-in-out`,
      }
    },
    fadeOut: () => {
      return {
        opacity:0,
        transition: `opacity ${250}ms ease-in-out`,
      }
    },

  }