import React, { useEffect, useState } from 'react'

import useScreenSize from '../hooks/useScreenSize'
import useMobileScreen from '../hooks/useMobileScreen'

//import ImageFooterBush from '../assets/images/home/Bush_Bottom.svg'
//import IconNuitsAlpha from '../assets/images/nuitsicon_white_alpha.png'

import { Link } from 'react-router-dom'
import { CDN_ENDPOINT } from '../logic/config'

/**
 * 
 * @returns 
 */
const Footer = (props) => {

    ///
    /// Hooks
    ///

    const screenSize = useScreenSize()
    const mobileScreen = useMobileScreen()


    ///
    /// States
    ///
    const [tint, setTint] = useState('white');
    const [showHeader, setShowHeader] = useState(false)

    /// IMAGES
    const [ImageFooterBush, setImageFooterBush] = useState()
    const [IconNuitsAlpha, setIconNuitsAlpha] = useState()

    ///
    /// EFFECTS
    /// 
    useEffect(()=>{

        if((props.lightContent ?? false) === true){
            setTint("#333")
        }

        /// Show title after 2 secs
        setTimeout(()=>{
            setShowHeader(true)
        }, 500)

        /// LOAD IMAGES
        const DEVELOPMENT = false //process.env.NODE_ENV === 'development'
        if(DEVELOPMENT){
            import('../assets/images/home/Bush_Bottom.svg').then(setImageFooterBush)
            import('../assets/images/nuitsicon_white_alpha.png').then(setIconNuitsAlpha)
        }else{
            setImageFooterBush(`${CDN_ENDPOINT}/web/assets/images/home/Bush_Bottom.svg`)
            setIconNuitsAlpha(`${CDN_ENDPOINT}/web/assets/images/nuitsicon_white_alpha.png`)
        }

    },[])
    
    ///
    /// RENDER
    ///

    return (
        <React.Fragment>
            {/*<div style={{width: '100%', height: '130px', marginBottom: '-5px', backgroundImage: `url(${ImageFooterBush})`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}/> */}
            <div style={{width: '100%', overflowX: 'hidden', marginBottom: '-5px'}}>
                <img src={ImageFooterBush} style={{width: '100%', transform: 'scale(1.1, 1.0)'}} alt='Screenshot of the iphone app, showing the mixer, where you can pick the sounds you want'/>
            </div>
            <div style={{...style.footerContainer(mobileScreen, screenSize), background: 'rgb(7,68,121)'}}>

                {/* SPACER */}
                <div style={style.footerSection(mobileScreen, screenSize)}></div>
                {/* Logo nuits */}
                <div style={{...style.footerSection(mobileScreen, screenSize), justifyContent: 'center', alignItems: 'center'}}>
                <Link reloadDocument to={'https://getnuits.com'} style={{ textDecoration: 'none' }}>
                <div style={{color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: '500', fontSize: 30}}>
                    <img src={IconNuitsAlpha} style={{width: 40}} width={40} height={40} alt='Nuits icon (A white moon with the shape of the letter N)'/>
                    nuits
                </div>
                </Link>
                <div style={{height: 40}}/>
                </div>

                {/* SPACER if not mobile*/}
                {
                    mobileScreen
                    ? <React.Fragment/>
                    : <div style={style.footerSection(mobileScreen, screenSize)}></div>
                }
                

                {/* Nuits App */}
                <div style={style.footerSection(mobileScreen, screenSize)}>
                <div style={style.footerSectionTitle(mobileScreen, screenSize)}>
                <h3 style={{margin: 0, fontWeight: '500'}}>Nuits App</h3>
                </div>
                <Link reloadDocument to={'/soundscapes'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Soundscapes
                </Link>
                <Link reloadDocument to={'/sounds'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Sounds
                </Link>
                <Link reloadDocument to={'/usecases'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Use cases
                </Link>
                <Link reloadDocument to={'/compare'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Comparisons
                </Link>
                </div>

                {/* Company */}
                <div style={style.footerSection(mobileScreen, screenSize)}>
                <div style={style.footerSectionTitle(mobileScreen, screenSize)}>
                <h3 style={{margin: 0, fontWeight: '500'}}>Company</h3>
                </div>
                <Link reloadDocument to={'https://nada.studio'} target={'_blank'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    About us
                </Link>
                <Link reloadDocument to={'/terms'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Terms & Conditions
                </Link>
                <Link reloadDocument to={'/privacy'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Privacy Policy
                </Link>
                </div>
                {/* Support & Social */}
                <div style={style.footerSection(mobileScreen, screenSize)}>
                <div style={style.footerSectionTitle(mobileScreen, screenSize)}>
                <h3 style={{margin: 0, fontWeight: '500'}}>Support & Social</h3>
                </div>
                <div style={{...style.footerSectionLink(mobileScreen, screenSize), cursor: 'pointer'}} onClick={async()=>{
                    //Build link here so it's not on the document and bots dont get it
                    var m = "m";
                    var a = "ailt"
                    var x = "o:hola"
                    var arr = "@"
                    var dom = "getnuits.com";
                    window.location.href=m+a+x+arr+dom;
                }}>
                    Contact us
                </div>
                <Link reloadDocument to={'https://twitter.com/getNuits'} target={'_blank'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Twitter / X
                </Link>
                <Link reloadDocument to={'https://instagram.com/getNuits'} target={'_blank'} style={style.footerSectionLink(mobileScreen, screenSize)}>
                    Instagram
                </Link>
                <Link reloadDocument to={'/blog/'} target={'_blank'} style={style.footerSectionLink(mobileScreen, screenSize)}> {/* IMPORTANT blog/ not blog, cause 301 redirect. see ahrefs to find unintendend 301 redirects */}
                    Blog
                </Link>
                </div>
                {/* SPACER */}
                <div style={style.footerSection(mobileScreen, screenSize)}></div>
            </div>
        </React.Fragment>
    )
}

export default Footer


const style = {

    //
    //CONTAINER
    //

    //The whole div parent
    container : (isMobile, screenSize) => {	
        return {
            position: 'absolute',
            top: 10,
            height: 80,
            //backgroundColor: 'red',
            width:  isMobile ? '85%' : '85%',
            maxWidth: 1400,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'white',
            fontFamily: 'Signifika',
            fontSize: 18,
            zIndex: 1000,
            // TO AVOID DELAY UNTIL FONT IS LOADED, START HIDDEN AND FADEIN
            transition: `opacity ${2000}ms ease-in-out`,
            opacity: 0,
        }
    },

    ///
    /// FOOTER
    ///
    footerContainer: (isMobile, screenSize) => {
        return {
            display: 'flex',
            width: '100%',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
            alignItems: 'stretch', //this forces the child to strech vertically
          }
      },

    footerSection: (isMobile, screenSize) => {
        return {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: isMobile ? 'center' : 'flex-start',
          flex: 1,
          paddingTop: isMobile ? 10 : 50,
          paddingBottom: isMobile ? 10 : 20,
          fontFamily: 'Signifika',
          color: 'white',
        }
      },
      footerSectionTitle: (isMobile, screenSize) => {
        return {
          color: 'white',
          fontSize: 18,
          fontFamily: 'Signifika', //Inter
          fontWeight: '500',
          textAlign: 'left',
          marginTop: 25,
          marginBottom: isMobile ? 15 : 30
        }
      },
      footerSectionLink: (isMobile, screenSize) => {
        return {
          color: 'white',
          fontSize: 16,
          fontFamily: 'Signifika', //Inter
          fontWeight: '300',
          textAlign: 'left',
          textDecoration: 'none',
          marginBottom: isMobile ? 15 : 10
        }
      },

    ///
    /// FADES
    ///

    fadeIn: () => {
        return {
          opacity:1,
          transition: `opacity ${2000}ms ease-in-out`,
        }
      },
      fadeOut: () => {
        return {
          opacity:0,
          transition: `opacity ${2000}ms ease-in-out`,
        }
      },
}