import React, {Component} from 'react'

class ButtonLight extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            hover: false,
        };
    }
    render(){
        return (
            <div style={this.state.hover ? this.style.buttoHover : this.style.button} onClick={this.props.onClickAction} onMouseEnter={()=>{this.setState({hover: true})}} onMouseLeave={()=>{this.setState({hover: false})}}>
                {this.props.title}
            </div>
        );
    }

    style = {
        button:{	
            cursor: 'pointer',
            boxSizing: 'border-box',
            height: '44px',
            fontSize: '16px',
            width: '100%',
            backgroundColor: '#ffffff',
            color: 'rgb(40,40,80)',
            fontWeight: '500',
            overflow: "hidden",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius:22,
            borderWidth: 0,
            WebkitBoxShadow: this.props.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),0px 0px 2px 0px #fff',
            boxShadow: this.props.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),0px 0px 2px 0px #fff',
            WebkitTransition: 'all 0.5s ease',
            transition: 'all 0.5s ease',
            
          },
          buttoHover:{	
            boxSizing: 'border-box',
            cursor: 'pointer',
            height: '44px',
            fontSize: '16px',
            width: '100%',
            backgroundColor: 'rgb(40,40,80)',
            color: '#fff',
            fontWeight: '500',
            overflow: "hidden",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius:22,
            borderWidth: 0,
            WebkitBoxShadow: this.props.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),0px 0px 12px 0px #fff',
            boxShadow: this.props.shadows===false ?  '0px 0px 0px 0px rgba(0,0,0,0.00)' : '4px 4px 8px 0px rgba(0,0,0,0.07),0px 0px 12px 0px #fff',
            WebkitTransition: 'all 0.5s ease',
            transition: 'all 0.5s ease',
          }
        }
}

export default ButtonLight