//process.env.NODE_ENV es automatica de react, y en yarn start, devuelve development
const BACKEND_API_URL = process.env.NODE_ENV === 'development' ? "http://localhost:5005/v1/web" : "https://backend.getnuits.com/v1/web";

//CDN endpoint
const CDN_ENDPOINT = "https://nuits-cdn.ams3.cdn.digitaloceanspaces.com"

module.exports = {
    BACKEND_API_URL: BACKEND_API_URL,
    CDN_ENDPOINT
}
