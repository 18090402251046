// useScreenSize.js
// https://medium.com/@josephat94/building-a-simple-react-hook-to-detect-screen-size-404a867fa2d2
import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: 10,//undefined, //window.innerWidth, - undefined because on sever side rendering window is null! - undefined caused some nil on css, so some number
    height: 10, //undefined, //window.innerHeight, - undefined because on sever side rendering window is null! - undefined caused some nil on css, so some number
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    //Now that we do not set an state initially, because of SSR
    //On first run, check if mobile
    setScreenSize({
        width: window.innerWidth, 
        height: window.innerHeight,
    })


    //Add listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;