// based on useScreenSize.js
// return true if it is mobile. based on screen size
import { useState, useEffect } from 'react';

const useMobileScreen = () => {

  const [isMobile, setIsMobile] = useState(
    false //window.innerWidth < 800 ? true : false  - undefined because on sever side rendering window is null!
    );

  useEffect(() => {
    const handleResize = () => {
        // width: window.innerWidth,
        //height: window.innerHeight,
        setIsMobile(window.innerWidth < 800 ? true : false)
    };

    //Now that we do not set an state initially, because of SSR
    //On first run, check if mobile
    if(window.innerWidth < 800){
      setIsMobile(true)
    }

    //Add listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useMobileScreen;