import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { CDN_ENDPOINT } from '../logic/config'

import useScreenSize from '../hooks/useScreenSize'
import useMobileScreen from '../hooks/useMobileScreen'

//import IconNuitsAlpha from '../assets/images/nuitsicon_white_alpha.png'

import ButtonLight from './ButtonLight'

/**
 * 
 * @param {bool} lightContent 
 * @returns 
 */
const Header = (props) => {

    ///
    /// Hooks
    ///

    const screenSize = useScreenSize()
    const mobileScreen = useMobileScreen()


    ///
    /// States
    ///
    const [tint, setTint] = useState('white');
    const [showHeader, setShowHeader] = useState(false)
    const [initialOpacity, setInitialOpacity] = useState(0)

    // IMAGES
    const [IconNuitsAlpha, setIconNuitsAlpha] = useState("")

    useEffect(()=>{
        if((props.lightContent ?? false) === true){
            setTint("#333")
        }

        if(props.shownInitially===true){
            setInitialOpacity(1)
        }else{
            /// Show title after 2 secs
            setTimeout(()=>{
                setShowHeader(true)
            }, 250)
        }

        /// LOAD IMAGES
        const DEVELOPMENT = false //process.env.NODE_ENV === 'development'
        if(DEVELOPMENT){
            import('../assets/images/nuitsicon_white_alpha.png').then(setIconNuitsAlpha)
        }else{
            setIconNuitsAlpha(`${CDN_ENDPOINT}/web/assets/images/nuitsicon_white_alpha.png`)
        }

    },[])
    
    ///
    /// RENDER
    ///

    return (
        <div style={{...style.container(mobileScreen, screenSize, initialOpacity), ...(showHeader ? style.fadeIn() : {})}} onClick={()=>window.location.href='/'}>
            <div style={{...style.contentLeft(mobileScreen, screenSize), color: tint}}>
                <img src={IconNuitsAlpha} style={{width: 26, marginRight: 6}} width={26} height={26} alt='Nuits icon (A white moon with the shape of the letter N)'/>
                <div style={{width: 2}}/>
                nuits
            </div>

            <div style={style.contentRight(mobileScreen, screenSize)}>
                {
                    mobileScreen
                    ? <React.Fragment>
                        
                    </React.Fragment>
                    : <Link to={'https://apps.apple.com/us/app/nuits-sleep-with-white-noise/id1667217062'} style={{width: '170px', textDecoration: 'none'}}>
                        <ButtonLight title="Try for Free"/>
                    </Link>
                }
            </div>
        </div>
    )
}

export default Header


const style = {

    //
    //CONTAINER
    //

    //The whole div parent
    container : (isMobile, screenSize, initialOpacity) => {	
        return {
            position: 'absolute',
            top: 10,
            height: 80,
            //backgroundColor: 'red',
            width:  isMobile ? '85%' : '85%',
            maxWidth: 1400,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'white',
            fontFamily: 'Signifika',
            fontSize: 18,
            zIndex: 1000,
            // TO AVOID DELAY UNTIL FONT IS LOADED, START HIDDEN AND FADEIN
            // UNLESS WE PROVIDE AN INITIAL OPACITY OF 1, WHEN NAVIGATING TO OTHER PAGES, WHEN WE DONT WANT FADEIN
            transition: initialOpacity===1 ? 'none' : `opacity ${1000}ms ease-in-out`,
            opacity: initialOpacity ?? 0,
        }
    },
    contentLeft : (isMobile, screenSize) => {	
        return {
            //backgroundColor: 'green',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 28,
            fontFamily: 'Signifika',
            fontWeight: '500',
            cursor: 'pointer'
        }
    },
    contentRight : (isMobile, screenSize) => {	
        return {
            //backgroundColor: 'blue',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontWeight: '400',
            fontSize: 16,
        }
    },

    ///
    /// FADES
    ///

    fadeIn: () => {
        return {
          opacity:1,
          transition: `opacity ${1000}ms ease-in-out`,
        }
      },
      fadeOut: () => {
        return {
          opacity:0,
          transition: `opacity ${1000}ms ease-in-out`,
        }
      },
}