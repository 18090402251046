import React, {Component} from 'react'

class AppStoreBadge extends Component{
    constructor(props){
        super(props)
    }

    _onPress = () => {
        var url = 'https://apps.apple.com/us/app/nuits-sleep-with-white-noise/id1667217062?itsct=apps_box_badge&amp;itscg=30200';
        var win = window.open(url, '_blank');
        win.focus();
    }
    render(){
        return(
            <div style={{ margin: '00px',  display: 'flex'}}>
                <div style={this._style.badge} onClick={()=>this._onPress()}/>
            </div>
        )
    }
    _style = ({
        badge: {
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            cursor: 'pointer',
            background: 'url(https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1702339200) no-repeat',
            width: '150px',
            height: '50px',
            borderRadius: '0px', 
            backgroundSize: 'contain',
        }
        
    })
}


export default AppStoreBadge