import React, {Component, lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom'
import { useEffect } from 'react';

import './App.css';

import Home from './pages/Home'

/*
import Privacy from './pages/Privacy';
import Terms from './pages/Terms.jsx';
import Invite from './pages/Invite';
import Soundscapes from './pages/Soundscapes.jsx';
import Sounds from './pages/Sounds.jsx';
import Usecases from './pages/Usecases.jsx';
import Compare from './pages/Compare.jsx';

import Error404 from './pages/Error404.jsx';

import Dashboard from './pages/Dashboard.jsx';
*/

// I REMOVED THIS CAUSE CAUSED DELAY ON GOOGLE VITALS - Find a way to make it without plugin
//import ReactGA from "react-ga4";
//ReactGA.initialize("G-WXB8E0BZN6");


//DISABLED IT FOR SSR FROM SERVER
//Lazy load to speed up landing page time! dont load the rest yet
//Tuto about this lazyload: https://medium.com/javascript-in-plain-english/code-splitting-for-efficient-loading-how-to-use-react-lazy-imports-suspense-and-hoc-fabc8fb6525a
const LazyLoad = ({component: Component, ...rest}) => { //I need to add a proper loading component where Reacr.fragment
  return (<Suspense fallback={<React.Fragment><div>Loading...</div></React.Fragment>}> 
            <Component {...rest} />
          </Suspense>)
}

// Lazy loads instead of regular, to save performance on hydration
const LazyDashboard = (props) => <LazyLoad component={lazy(() => import('./pages/Dashboard.jsx'))}{...props} />
const LazySoundscapes = (props) => <LazyLoad component={lazy(() => import('./pages/Soundscapes.jsx'))}{...props} />
const LazySounds = (props) => <LazyLoad component={lazy(() => import('./pages/Sounds.jsx'))}{...props} />
const LazyPrivacy = (props) => <LazyLoad component={lazy(() => import('./pages/Privacy.jsx'))}{...props} />
const LazyTerms = (props) => <LazyLoad component={lazy(() => import('./pages/Terms.jsx'))}{...props} />
const LazyError404 = (props) => <LazyLoad component={lazy(() => import('./pages/Error404.jsx'))}{...props} />
const LazyUsecases = (props) => <LazyLoad component={lazy(() => import('./pages/Usecases.jsx'))}{...props} />
const LazyCompare = (props) => <LazyLoad component={lazy(() => import('./pages/Compare.jsx'))}{...props} />
const LazyInvite = (props) => <LazyLoad component={lazy(() => import('./pages/Invite.jsx'))}{...props} />

function App() {

  //TODO: read how to track with analytics:
  // https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/
  /*
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[]);
  */

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />

        <Route path="/404" element={ <LazyError404/> } />

        <Route path="/terms" element={ <LazyTerms/> } />
        <Route path="/privacy" element={ <LazyPrivacy/> } />

        <Route path="/usecases" element={ <LazyUsecases/> } />
        <Route path="/usecases/:usecase" element={ <LazyUsecases/> } />

        <Route path="/compare" element={ <LazyCompare/> } />
        <Route path="/compare/:target" element={ <LazyCompare/> } />

        <Route path="/sounds" element={ <LazySounds/> } />
        <Route path="/sounds/:sound" element={ <LazySounds/> } />

        <Route path="/soundscapes" element={ <LazySoundscapes/> } />
        <Route path="/soundscapes/:soundscape" element={ <LazySoundscapes/> } />

        <Route path='/i/:invitecode' element= { <LazyInvite/>} />

        <Route path='/dashboard' element={<LazyDashboard/>}/>
        <Route path='/dashboard/:section' element={<LazyDashboard/>}/>
        {/*
        <Route path='/dashboard' element={<LazyDashboard/>}/>
        <Route path='/dashboard/:section' element={<LazyDashboard/>}/>
        */}
        <Route path='*' element={<LazyError404/>}/>
      </Routes>
    </div>
  );
}



export default App;

//We need to wrap in <html for it to work with SSR hydration streampipe
//https://react.dev/reference/react-dom/server/renderToPipeableStream#rendertopipeablestream
/// WE COPIED THE index.html into a react component to be able to work with streampipe lazy load SSR

export function AppWrappedHTML(props) {
  return (<HTMLIndex {...props}><App/></HTMLIndex>
  );
}
function HTMLIndex(props) {
  
  const cssStyle = `
    @font-face {
      font-family: "Signifika";
      src: local("Signifika"),
          url("/Signika-VariableFont_wght.woff2") format("woff2");
      font-display: swap;
    }
    /* You can continue adding more CSS here */
    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: rgb(41, 40, 80);
    }
  `;

  return (
      <html lang="en">
      <head>
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1"  />
      <meta name="theme-color" content="#1c56a7" />
      <meta name="keywords" content="noise white noise,white noise sleep,rain sounds,soundscapes,calm app,white noise sounds,white sound machine,relax,meditation,meditate,app,focus,soundscape,stress,insomnia,anxiety" />
      <meta name="description" content={props.description ?? "NO DESCRIPTION"} />

      <link rel="apple-touch-icon" href="/logo192.png" />
      <link rel="canonical" href={props.canonical} />

      <meta property="og:title" content={props.title ?? "NO TITLE"}/>
      <meta property="og:type" content="website"/>
      <meta property="og:image" content={"/thumbnail_social.png"}/>
      <meta property="og:url" content="https://getnuits.com"/>
      <meta property="og:description" content={props.description ?? "NO DESCRIPTION"}/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@getNuits"/>
      <meta name="twitter:creator" content="@getNuits"/>
      <meta name="twitter:title" content="Nuits. Better Focus, Relax and Sleep"/>
      <meta name="twitter:description" content="Calming visual soundscapes to improve Focus, Relax, and Sleep."/>

      <meta name="apple-itunes-app" content="app-id=1667217062"/>

      <link rel="manifest" href="/manifest.json" />
      
      {/*<link rel="stylesheet" href={"/appwrappedhtml.css"} />*/}
      {/* Trick to include HTML CSS inline without breaking stuff, and avoiding the HTTPS call to the resource */}
      <style dangerouslySetInnerHTML={{ __html: cssStyle }} />

      <title>{props.title ?? "NO TITLE"}</title>
      </head>
      <body>    
      <noscript>You need to enable JavaScript to run this app.</noscript>
      <div id="root">
      {props.children}
      </div>
      </body>
      </html>
  )
}